$( document ).ready(function() {
    if ( !$('html').hasClass('hydrated') ) {
        _aos();
    }
});
const _aos = () => {
    setTimeout(() => {
        try {
            AOS.init({
                disable: function() {
                    let _matchMedia = '991.98px';
                    return window.matchMedia('(max-width: ' + _matchMedia + ')').matches;
                },
                duration: 600,
                easing: 'ease-out-back'
            });
            onElementHeightChange(document.body, function(){
                AOS.refresh();
            });
        }
        catch (error) {
            console.log(error);
        }
    }, 320);
    function onElementHeightChange(elm, callback) {
        let lastHeight = elm.clientHeight;
        let newHeight;
        
        (function run() {
            newHeight = elm.clientHeight;      
            if (lastHeight !== newHeight) callback();
            lastHeight = newHeight;
    
            if (elm.onElementHeightChangeTimer) {
              clearTimeout(elm.onElementHeightChangeTimer); 
            }
    
            elm.onElementHeightChangeTimer = setTimeout(run, 320);
        })();
    }
}